<template>
    <div class="block">
        <el-timeline style="padding-inline-start: 20px;">
            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_policy_title")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_policy_date")}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_policy_body1")+$t("label_privacy_policy_body2")+$t("label_privacy_policy_body3")}}</p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_definition")}}</h3>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="$t('label_privacy_definition_personal')" name="1">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_personal_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_usage')" name="2">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_usage_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_cookies')" name="3">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_cookies_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_controller')" name="4">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_controller_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_processor')" name="5">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_processor_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_processor_data2")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_subject')" name="6">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_subject_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_definition_user')" name="7">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_definition_user_data")}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_collect")}}</h3>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="$t('label_privacy_collect_personal')" name="1">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_personal_data")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in items" :timestamp="$t(str)" />
                            </el-timeline>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_personal_data1")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_collect_usage')" name="2">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_usage_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_usage_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_usage_data3")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_collect_device')" name="3">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_device_data")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_collect_security')" name="4">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_security_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_collect_location')" name="5">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_location_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_location_data2")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_collect_internet')" name="6">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_internet_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_collect_devicelist')" name="7">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_devicelist_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_collect_file')" name="8">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_file_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_collect_cookies')" name="9">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_cookies_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_cookies_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_cookies_data3")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in cookies" :timestamp="$t(str)" />
                            </el-timeline>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_collect_sensor')" name="10">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_sensor_data")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in sensors" :timestamp="$t(str)" />
                            </el-timeline>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_collect_other')" name="11">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_collect_other_data")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in others" :timestamp="$t(str)" />
                            </el-timeline>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-timeline-item>

            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_how")}}</h3>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="$t('label_privacy_bozee')" name="1">
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in dataStr" :timestamp="$t(str)" />
                            </el-timeline>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-timeline-item>


            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_reserve")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_reserve_data1")}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_reserve_data2")}}</p>
                </el-card>
            </el-timeline-item>

            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_shared")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_shared_data1")}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_shared_data2")}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_shared_data3")}}</p>
                </el-card>
            </el-timeline-item>

            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_law")}}</h3>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="$t('label_privacy_law_disclosure')" name="1">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_law_disclosure_data")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_law_require')" name="2">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_law_require_data")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item v-for="str in lawStr" :timestamp="$t(str)" />
                            </el-timeline>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-timeline-item>


            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_safety")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_safety_data")}}</p>
                </el-card>
            </el-timeline-item>


            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_delete_data")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_delete_data1")+$t("label_privacy_delete_data2")+$t("label_privacy_delete_data3")}}</p>
                    <p>{{$t("label_privacy_delete_data4")}}</p>
                    <el-timeline :reverse="true">
                        <el-timeline-item :timestamp="$t('label_privacy_delete_data5')" />
                        <el-timeline-item :timestamp="$t('label_privacy_delete_data6')" />
                    </el-timeline>
                </el-card>
            </el-timeline-item>


            <el-timeline-item placement="top" style="text-align: left;">
                <el-card>
                    <h3>{{$t("label_privacy_cooperate")}}</h3>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_body")}}</p>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="$t('label_privacy_cooperate_analyze')" name="1">
                            <div>{{$t("label_privacy_cooperate_analyze_data")}}</div>
                            <div>{{$t("label_privacy_cooperate_analyze_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_analyze_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_analyze_data3")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_analyze_data4")}}</div>


                            <div>{{$t("label_privacy_cooperate_firebase")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_firebase_data")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_firebase_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_firebase_data2")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_cooperate_advertise')" name="2">
                            <div>{{$t("label_privacy_cooperate_advertise_data")}}</div>
                            <div>{{$t("label_privacy_cooperate_advertise_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_advertise_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_advertise_data3")}}</div>


                            <div>{{$t("label_privacy_cooperate_advertise_data4")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_advertise_data5")+$t("label_privacy_cooperate_advertise_data6")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_advertise_data7")}}</div>
                        </el-collapse-item>
                        <el-collapse-item :title="$t('label_privacy_cooperate_behavior')" name="3">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_behavior_data")}}</div>
                            <div>{{$t("label_privacy_cooperate_behavior_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_behavior_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_behavior_data3")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_behavior_data4")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_cooperate_payment')" name="4">
                            <div>{{$t("label_privacy_cooperate_payment_data")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_payment_data1")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_payment_data2")}}</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_payment_data3")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_cooperate_link')" name="5">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_link_data")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_cooperate_child')" name="6">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_child_data")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_cooperate_policy')" name="7">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;{{$t("label_privacy_cooperate_policy_data")}}</div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('label_privacy_cooperate_connect')" name="8">
                            <div>{{$t("label_privacy_cooperate_connect_data")}}</div>
                            <el-timeline :reverse="true">
                                <el-timeline-item :timestamp="$t('label_privacy_cooperate_connect_data1')" />
                                <el-timeline-item :timestamp="$t('label_privacy_cooperate_connect_data2')" />
                            </el-timeline>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </el-timeline-item>

        </el-timeline>
    </div>

</template>

<script>
    export default {
        name: "MobilePrivacy",
        data(){
            return {
                activeNames:['1','2','3','4','5','6','7','8','9','10','11','12','13'],
                items: [
                    'label_privacy_collect_personal_data_mail',
                    'label_privacy_collect_personal_data_name',
                    'label_privacy_collect_personal_data_tel',
                    'label_privacy_collect_personal_data_address',
                    'label_privacy_collect_personal_data_cookiesAusage',
                ],
                cookies:[
                    'label_privacy_collect_cookies_data4',
                    'label_privacy_collect_cookies_data5',
                    'label_privacy_collect_cookies_data6',
                    'label_privacy_collect_cookies_data7',
                ],
                sensors:[
                    'label_privacy_collect_sensor_data1',
                    'label_privacy_collect_sensor_data2',
                    'label_privacy_collect_sensor_data3',
                    'label_privacy_collect_sensor_data4',
                    'label_privacy_collect_sensor_data5',
                ],
                others:[
                    'label_privacy_collect_other_data1',
                    'label_privacy_collect_other_data2',
                    'label_privacy_collect_other_data3',
                    'label_privacy_collect_other_data4',
                    'label_privacy_collect_other_data5',
                    'label_privacy_collect_other_data6',
                    'label_privacy_collect_other_data7',
                ],
                dataStr:[
                    'label_privacy_bozee_data1',
                    'label_privacy_bozee_data2',
                    'label_privacy_bozee_data3',
                    'label_privacy_bozee_data4',
                    'label_privacy_bozee_data5',
                    'label_privacy_bozee_data6',
                    'label_privacy_bozee_data7',
                    'label_privacy_bozee_data8',
                ],
                lawStr:[
                    'label_privacy_law_require_data1',
                    'label_privacy_law_require_data2',
                    'label_privacy_law_require_data3',
                    'label_privacy_law_require_data4',
                    'label_privacy_law_require_data5',
                ],
            }
        },
        mounted() {
            document.title = this.$t('label_privacy_title');
        },
    }
</script>
